<template>
  <div>
    <h3 ref="ia">
      RC Circuits
    </h3>
    <p>
      Ohm's law, named after German physicist Georg Simon Ohm, is one of the fundamental principles of electricity.
      Ohm's law relates the voltage applied across a conducting material to the electric current flowing through it.
      Ohm, while experimenting with electrochemical cells, found that the electric current flowing through a conducting material is directly proportional to the voltage applied.
      Mathematically, Ohm's law can be expressed as:
      $$ I \propto V $$
      Expressed as an equality (by introducing a proportionality constant \(R\)):
      $$ I = \frac{V}{R}$$
      where \(V\) is the voltage loss across the conductor, \(I \) is the electric current through it. \(R\) is the ratio of voltage to current, and is known as the electric resistance of the conductor.
      For certain materials, this ratio \(R\) remains constant over a broad range of values of \(V\) and \(R\). Such conductors are known as Ohmic conductors.
    </p>
    <h3 ref="ca">
      Implications of Ohm's Law
    </h3>
    <p>
      Based on Ohm's law, the following conclusions can be deduced about electric circuits.
      <ul style="list-style-type:square;">
        <li>
          <h3> Constant Voltage Circuit </h3>  When the voltage applied to an electric circuit is kept constant (i.e. connected to a source of constant voltage), the electric current decreases with resistance.
        </li>
        <li>
          <h3> Constant Resistance Circuit</h3> When the resistance is kept constant, the electric current linearly increases with applied voltage.
        </li>
        <li>
          <h3> Constant Current Circuit </h3> When the electric current flowing through the circuit is kept constant, the electric current linearly increases with applied voltage.
        </li>
      </ul>
    </p><h3 ref="playgraph">
      Playgraph - Ohm's Law
    </h3>
    <p>
      The goal of this interactive session is to help students:
      <ul style="list-style-type:square;">
        <li> learn the Ohm's law.</li>
        <li> understand how electric current relates to applied voltage in an Ohmic conductor. </li>
        <li> learn how electric current varies with resistance. </li>
      </ul>
    </p>
    <v-layout justify-center>
      <div id="jxgbox1" class="jxgbox edliy-box-about" style="width:800px; height:800px;" />
    </v-layout>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'RC',
  created: function () {
    // Store mutations
    let title = 'RC Circuits';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'RC Circuits', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Implications of Ohm\'s law', img:'/assets/number-2.svg', action: () => this.goto('ca')},
      {title: 'PlayGraph: Ohm\'s Experiment',img:'/assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule); 
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
