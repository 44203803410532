const Boxes = {
  box1: function () {
    var brd2 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-2, 7, 12, -7],keepaspectratio: true, axis:false, ticks:false, grid:false, pan:{enabled:false}, showCopyright:false, showNavigation:false});
    brd2.options.layer['line'] =6;
    brd2.options.layer['segment'] =9;
    brd2.options.layer['polygon'] =2;
    brd2.options.layer['image'] =8;
    var title = brd2.create('text',[2, 6.5, 'Turn the switch on/off. Then tap on the battery to start!'],{parse:true, cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}, fixed:true});
    brd2.create('axis', [[-1, -6],[2, -6]],{name:'Time(sec)', withLabel:true, label:{position:'top', fontSize:function(){return Math.round(18*brd2.canvasWidth/500.)}, cssStyle:'fontFamily:Oswald;'}});
    brd2.create('axis', [[0, -6],[0, -2]],{name:'Charge (C)',withLabel:true, label:{position:'top',fontSize:function(){return Math.round(18*brd2.canvasWidth/500.)}, cssStyle:'fontFamily:Oswald;'}});
    var a = brd2.create('slider',[[-1+5,5],[5+5,5],[0, 2.5, 5]],{baseline:{strokeWidth:7, strokeColor:'grey'},highline:{strokeWidth:7, strokeColor:'black'}, name:'V(Volts)',size:8,face:'square', fillColor:'grey',strokeColor:'black', withTicks:false, label:{fontSize:function(){return Math.round(12*brd2.canvasWidth/500.)}, cssStyle:'fontFamily:Oswald;'}});
    var r = brd2.create('slider',[[2.5,0],[2.5,3],[0, 2.5, 5]],{baseline:{strokeWidth:7, strokeColor:'grey'},highline:{strokeWidth:7, strokeColor:'black'}, name:'R(&Omega;)',size:8,face:'square', fillColor:'grey',strokeColor:'black', withTicks:false, label:{fontSize:function(){return Math.round(12*brd2.canvasWidth/500.)}, cssStyle:'fontFamily:Oswald;'}});
    var c = brd2.create('slider',[[5,-1],[8,-1],[0, 2.5, 5]],{baseline:{strokeWidth:7, strokeColor:'grey'},highline:{strokeWidth:7, strokeColor:'black'}, name:'C(F)',size:8,face:'square', fillColor:'grey',strokeColor:'black', withTicks:false, label:{fontSize:function(){return Math.round(12*brd2.canvasWidth/500.)}, cssStyle:'fontFamily:Oswald;'}});
    brd2.create('image', ['/assets/switch.svg', [5.35+5,1.55],[1.3,1.3]],{fixed:true, rotate:0});
    var b = brd2.create('slider',[[6+5,2.],[6+5,2.4],[0, 1, 1]],{baseline:{strokeWidth:0, strokeColor:'grey'},highline:{strokeWidth:0, strokeColor:'black'},
    name:'',size:6.,face:'square', strokeWidth:2, snapWidth:1, fillColor:'grey',strokeColor:'black', withLabel:false});
    var chg=brd2.create('image', ['/assets/ui.svg', [3.+5,+3.25],[1.5,1.5]],{fixed:true, rotate:90});
    brd2.create('image', ['/assets/resistor.svg', [-1.75+5,1.0],[1.5,1.5]],{fixed:true});
    brd2.create('image', ['/assets/capacitor-2.svg', [1.5+5,0],[1.5,1.5]],{fixed:true});
    //
    brd2.create('image', ['/assets/arrowhead.svg', [0.75+5, -0.2],[0.4,0.4]],{fixed:true, rotate:90, visible:function(){if(b.Value()==0){return true}}});
    brd2.create('image', ['/assets/arrowhead.svg', [3.8+5,-0.2],[0.4,0.4]],{fixed:true, rotate:90,visible:function(){if(b.Value()==0){return true}}});
    brd2.create('image', ['/assets/arrowhead.svg', [0.75+5,3.2],[0.4,0.4]],{fixed:true, rotate:270,visible:function(){if(b.Value()==0){return true}}});
    brd2.create('image', ['/assets/arrowhead.svg', [3.80+5,3.2],[0.4,0.4]],{fixed:true, rotate:270,visible:function(){if(b.Value()==0){return true}}});
    brd2.create('image', ['/assets/arrowhead.svg', [5.20+5,2.2],[0.4,0.4]],{fixed:true, rotate:180,visible:function(){if(b.Value()==0){return true}}});
    brd2.create('image', ['/assets/arrowhead.svg', [-1.2+5,0.6],[0.4,0.4]],{fixed:true, rotate:0,visible:function(){if(b.Value()==0){return true}}});
    //Off State
    brd2.create('image', ['/assets/arrowhead.svg', [0.75+5,3.8],[0.4,0.4]],{fixed:true, rotate:90,visible:function(){if(b.Value()==1){return true}}});
    brd2.create('image', ['/assets/arrowhead.svg', [3.80+5,3.8],[0.4,0.4]],{fixed:true, rotate:90,visible:function(){if(b.Value()==1){return true}}});
    brd2.create('image', ['/assets/arrowhead.svg', [-0.8+5,0.8],[0.4,0.4]],{fixed:true, rotate:180,visible:function(){if(b.Value()==1){return true}}});
    brd2.create('image', ['/assets/arrowhead.svg', [5.2+5,2.0],[0.4,0.4]],{fixed:true, rotate:180,visible:function(){if(b.Value()==1){return true}}});
    brd2.create('image', ['/assets/arrowhead.svg', [0.75+5, 0.2],[0.4,0.4]],{fixed:true, rotate:270, visible:function(){if(b.Value()==1){return true}}});
    brd2.create('image', ['/assets/arrowhead.svg', [3.8+5,0.2],[0.4,0.4]],{fixed:true, rotate:270,visible:function(){if(b.Value()==1){return true}}});
    //var res3 = brd2.create('image', ['capacitor-2.svg', [0.5,-5.5],[1.5,1.5]],{attractors:[li1, li2], attractorDistance:1.5, snatchDistance:1.5});
    //var res4 = brd2.create('image', ['capacitor-2.svg', [3.5,-5.5],[1.5,1.5]],{attractors:[li1], attractorDistance:1.5, snatchDistance:1.5});
    brd2.create('polygon', [[-1+5, -0], [-1+5, 4], [5+5, 4],[5+5, -0]], {fixed:true, vertices:{visible:false, fixed:true}, borders:{strokeColor:'grey', strokeWidth:4}, fillOpacity:0, visible:true,strokeWidth:4, strokeColor:'orange'});
    brd2.create('polygon', [[-1+5, -0], [-1+5, 3], [5+5, 3],[5+5, -0]], {fixed:true, vertices:{visible:false, fixed:true}, borders:{strokeColor:'grey', strokeWidth:4}, fillOpacity:0, visible:true,strokeWidth:4, strokeColor:'orange'});
    brd2.create('segment', [[-1+5, +4.05], [-1+5, +3.05]], {visible:function(){if(b.Value()==0){return true}else{return false}}, strokeWidth:5, strokeColor:'oldLace'});
    brd2.create('segment', [[-0.96+5, +3.0], [0+5, +3.0]], {visible:function(){if(b.Value()==1){return true}else{return false}}, strokeWidth:5, strokeColor:'oldLace'});
    brd2.create('point', [-1+5, 4], {size:'3', name:'', fillColor:'black', strokeColor:'black'});
    brd2.create('point', [-1+5, 3], {size:'3', name:'', fillColor:'black', strokeColor:'black'});
    brd2.create('point', [0+5, 3], {size:'3', name:'', fillColor:'black', strokeColor:'black'});
// brd2.create('functiongraph', [function(x){return x-4}, 0, 3], {size:'3', name:'', fillColor:'black', strokeColor:'black'});
//var chg = brd2.create('image', ['electricity.svg', [6.25,2.25],[0.5,0.5]],{fixed:true});
    var xn=0, yn=0, Qr=0, Qc=0, x=0;
    var charge = function(x){return b.Value()*(a.Value()*c.Value()-(a.Value()*c.Value()-Qr)*Math.exp(-(x-yn)/r.Value()/c.Value()))+
      (1-b.Value())*Qc*Math.exp(-(x-xn)/r.Value()/c.Value())-6};
      var t = brd2.create('turtle', [x, charge(x)]);
      t.setPenSize(4);
      var run = function(){
        if ( x < 10) {  // stop after two rounds
          x +=0.1;
          if(b.Value()==1){
            xn =x;
            Qc =a.Value()*c.Value()-(a.Value()*c.Value() - Qr)*Math.exp(-(xn-yn)/r.Value()/c.Value());
          }
          else {
            yn =x;
            Qr = Qc*Math.exp(-(yn-xn)/r.Value()/c.Value());
          }
          t.moveTo([x, charge(x)]);
          setTimeout(run, 200);
        }};
        t.hideTurtle();
        chg.on('down', function(){xn=0; yn=0; x=0; Qr=0; Qc=0; t.cs(); t.init(x, charge(x)); run()});
              }
            }
export default Boxes;
